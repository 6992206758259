export enum SidebarIconName {
  dashboard = 'dashboard',
  timeline = 'timeline',
  messages = 'messages',
  photos = 'photos',
  documents = 'documents',
  warranty = 'warranty',
}

export enum Loader {
  hide = 'Hide',
  show = 'Show',
}
